<template>
  <div>
    <PageHeader>
      <template slot="right">
        <b-button
          variant="primary"
          @click="toggleModal()"
        >
          <i class="uil-plus mr-1"></i>
          {{ $t('buttons.add') }}
        </b-button>
      </template>
    </PageHeader>
    <b-overlay :show="isFetching">
      <b-card>
        <b-table
          :items="permissions"
          :fields="fields"
          responsive
          show-empty
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(description)="data">
            <PermissionDescriptionForm
              :value="data.item"
              :is-loading="isUpdating"
              @submit="onUpdateDescription"
            />
          </template>
          <template #cell(created_at)="data">
            {{ data.value | datetime }}
          </template>
          <template #cell(id)="data">
            <b-button
              variant="danger"
              size="sm"
              @click="onDelete(data.value)"
            >
              <i class="uil uil-trash-alt"></i>
            </b-button>
          </template>
          <template #empty="">
            <p class="text-center text-muted">{{ $t('messages.nothing_here')}}</p>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
    <b-modal
      v-model="isModalActive"
      :title="`${$t('buttons.add')} ${$t('roles.permissions').toLowerCase()}`"
      hide-footer
    >
      <ValidationObserver
        ref="permissionForm"
        v-slot="{ handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
            v-slot="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.number="permissionForm.name"
                :state="errors[0] ? false : null"
              ></b-form-input>
            </b-form-group>
          </ValidationProvider>
          <div class="text-right">
            <b-button
              variant="light"
              class="mr-2"
              @click="onCloseModal"
            >
              {{ $t('buttons.cancel')}}
            </b-button>
            <b-overlay
              :show="isAdding"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('buttons.confirm')}}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'กำหนดสิทธิ์',
  },
  components: {
    PermissionDescriptionForm: () =>
      import('../../../components/forms/permission-description-form'),
  },
  data() {
    return {
      isModalActive: false,
      fields: [
        '#',
        {
          key: 'name',
          label: this.$t('roles.permissions'),
        },
        {
          key: 'description',
          label: this.$t('fields.description'),
        },
        {
          key: 'created_at',
          label: this.$t('fields.createdAt'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'id',
          label: this.$t('buttons.remove'),
          class: 'text-right',
        },
      ],
      permissionForm: {
        name: '',
      },
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.role.isFetchingPermissions,
      isAdding: (state) => state.role.isAddingPermission,
      isUpdating: (state) => state.role.isUpdatingPermission,
    }),
    ...mapGetters(['permissions']),
  },
  watch: {},
  created() {
    this.fetchPermissions()
  },
  methods: {
    ...mapActions([
      'fetchPermissions',
      'deletePermission',
      'addPermission',
      'updatePermission',
    ]),
    toggleModal() {
      this.permissionForm.name = ''
      this.isModalActive = !this.isModalActive
    },
    onCloseModal() {
      this.isModalActive = false
    },
    onDelete(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deletePermission(id)
          }
        })
    },
    onUpdateDescription(data) {
      this.updatePermission({
        id: data.id,
        data,
      })
    },
    async onSubmit() {
      await this.addPermission(this.permissionForm)
      this.onCloseModal()
    },
  },
}
</script>
